<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Pembayaran
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()"  >
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Tanggal Order 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar  :showButtonBar="true" class="w-full" :class="getErrorClass('tanggal_order')" dateFormat="yy-mm-dd" v-model="formData.tanggal_order" :showIcon="true"     mask="YYYY-MM-DD"      />
                                                    <small v-if="isFieldValid('tanggal_order')" class="p-error">{{ getFieldError('tanggal_order') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    No Invoice 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlno_invoice" v-model.trim="formData.no_invoice"  label="No Invoice" type="number" placeholder="Enter No Invoice"   step="any"  readonly  
                                                    class=" w-full" :class="getErrorClass('no_invoice')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('no_invoice')" class="p-error">{{ getFieldError('no_invoice') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Jenis Pembayaran 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrljenis_pembayaran" v-model.trim="formData.jenis_pembayaran"  label="Jenis Pembayaran" type="text" placeholder="Enter Jenis Pembayaran"    readonly  
                                                    class=" w-full" :class="getErrorClass('jenis_pembayaran')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('jenis_pembayaran')" class="p-error">{{ getFieldError('jenis_pembayaran') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlalamat_kirim"  ref="ctrlalamat_kirim" v-model="formData.alamat_kirim" type="hidden" />
                                        <input name="ctrlusername_id"  ref="ctrlusername_id" v-model="formData.username_id" type="hidden" />
                                        <input name="ctrlkode_transaksi"  ref="ctrlkode_transaksi" v-model="formData.kode_transaksi" type="hidden" />
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Proses" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	    import {  computed,  reactive, toRefs, onMounted } from 'vue';
    import { required, numeric, } from 'src/services/validators';
    import { utils } from 'src/utils';
    import { useApp } from 'src/composables/app.js';
    import { useAuth } from 'src/composables/auth';
    import { useAddPage } from 'src/composables/addpage.js';
    import { usePageStore } from 'src/store/page';
    import axios from 'axios'; // import axios
    //[--PAGE-IMPORT-STATEMENT--]
    const props = defineProps({
        pageStoreKey: {
            type: String,
            default: 'PEMBAYARAN',
        },
        pageName : {
            type : String,
            default : 'pembayaran',
        },
        routeName : {
            type : String,
            default : 'pembayaranadd',
        },
        apiPath : {
            type : String,
            default : 'pembayaran/add',
        },
        submitButtonLabel: {
            type: String,
            default: "Proses",
        },
        formValidationError: {
            type: String,
            default: "Form is invalid",
        },
        formValidationMsg: {
            type: String,
            default: "Please complete the form",
        },
        msgTitle: {
            type: String,
            default: "Create Record",
        },
        msgAfterSave: {
            type: String,
            default: "Data berhasil ditambahkan",
        },
        msgBeforeSave: {
            type: String,
            default: "",
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
        redirect: {
            type : Boolean,
            default : true,
        },
        isSubPage: {
            type : Boolean,
            default : false,
        },
        pageData: { // use to set formData default values from another page
            type: Object,
            default: () => {} 
        },
    });
    //lines
    const store = usePageStore(props.pageStoreKey);
    const app = useApp();
    const auth = useAuth();
    const formDefaultValues = {
        tanggal_order: new Date(), 
        no_invoice: utils.randomNum(12), 
        jenis_pembayaran: "Transfer", 
        alamat_kirim: auth.userId, 
        username_id: auth.userName, 
        kode_transaksi: utils.randomStr(12), 
    };
    const formData = reactive({ ...formDefaultValues });
    //vuelidate form validation rules
    const rules = computed(() => {
        return {
            tanggal_order: {  },
            no_invoice: { numeric },
            jenis_pembayaran: {  },
            alamat_kirim: {  },
            username_id: {  },
            kode_transaksi: {  }
        }
    });
    const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
    //event raised before form submit
    async function beforeSubmit(){ // make the function async
    try { // use try...catch
        var data = JSON.stringify({
            "chat_id": "-4236195207", // replace with your chat id
            "text": "Username "+formData.username_id+",\nbaru saja melakukan pemesanan barang untuk Tanggal "+formData.tanggal_order+" dengan No. Invoice "+formData.no_invoice+".\n\nSilahkan login aplikasi untuk memvalidasi dan memproses barang. Terima kasih"
            // replace with your text
        });
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.telegram.org/bot7401791559:AAEHlrF7ofsgxcL7kEpLW6_R8e5KV-B549c/sendMessage', // replace with your bot url
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        const response = await axios(config); // use await to get the response
        console.log(JSON.stringify(response.data)); // print the response data
    }
    catch (error) { // catch the error
        console.log(error); // print the error
    }
    return true;
    }
    // event raised after form submited
    function afterSubmit(response) { 
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        page.setFormDefaultValues(); //reset form data
        if(app.isDialogOpen()){
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if(props.redirect) {
            app.navigateTo(`/pembayaran`);
        }
    }
    const {  saving, pageReady, } = toRefs(page.state);
    const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
    onMounted(()=>{
        const pageTitle = "Pembayaran";
        app.setPageTitle(props.routeName, pageTitle); // set browser page title
    });
    // expose page object for other components access
    defineExpose({
        page
    });
</script>
<style scoped>
</style>
<style scoped>

</style>
